<template>
  <div class="container-fluid p-1 position-relative ">
    <div class="row">
      <div class="d-flex justify-content-between w-100">
        <div class="px-1">
          <router-link
            :to="{ name: 'Pills' }"
            class="btn bg-gradient-info top-button"
          >
            <i class="bi bi-arrow-left-circle"></i> &nbsp;&nbsp;Back
          </router-link>
        </div>
      </div>
    </div>
  </div>  
  
  <div class="row">
    <div class="col-12">
      <div class="card my-4">
        <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2 mb-4">
          <div class="row bg-gradient-info shadow-black border-radius-lg pt-4 pb-3 mb-3">
            <h6 class="col text-white text-capitalize ps-3">Pill Registrations #{{ received_id }}</h6>
          </div>

          <Loading message="Loading Pill Registrations" :loading = "loading"/>
        
          <div v-if="loading == false" class="col-lg-20 mb-md-0 mb-4">
            <div class="row">
              <message-card
                :nameCard="'Status initial'"
                :amount="countRegistrations.status_initial"
                :color="'info'"
              ></message-card>
              
              <message-card
                :nameCard="'Status answered'"
                :amount="countRegistrations.status_answered"
                :color="'info'"
              ></message-card>

              <message-card
                :nameCard="'Clicked'"
                :amount="countRegistrations.clicked"
                :color="'info'"
              ></message-card>

              <message-card
                :nameCard="'Error'"
                :amount="countRegistrations.error"
                :color="'danger'"
              ></message-card>  

              <message-card
                :nameCard="'Waiting'"
                :amount="countRegistrations.waiting"
                :color="'warning'"
              ></message-card>

              <message-card
                :nameCard="'Sent pill'"
                :amount="countRegistrations.sent_pill"
                :color="'success'"
              ></message-card>

              <message-card
                :nameCard="'Sent mensage'"
                :amount="countRegistrations.sent_mensage"
                :color="'success'"
              ></message-card>
            </div>

            <div class="col-lg-20 mb-md-0 mb-4">
              <div class="table-responsive align-items-center mb-0 " style = "margin: 0px 20px; border-top-left-radius: 10px !important; border-top-right-radius: 10px !important;">
                <table class="table-responsive table table-striped table-hover">
                    <thead class="bg-info text-white">
                        <tr>
                            <th class="text-left px-2 text-wrap">Pill</th>
                            <th class="text-center text-wrap">Student</th>
                            <th class="text-center text-wrap">Status</th>
                            <th class="text-center text-wrap">Error detail</th>
                            <th class="text-center text-wrap">Pill status</th>
                            <th class="text-center text-wrap">Message status</th>
                            <th class="text-center text-wrap">Created</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="registration in PillsRegistrations">
                            <td class="text-left align-middle text-wrap">{{ registration.pill_name }}</td>
                            <td class="text-center align-middle text-wrap">{{ registration.student_info }}</td>
                            <td class="text-center align-middle text-wrap">{{ registration.status }}</td>
                            <td class="text-center align-middle text-wrap">{{ truncateText(registration.error_detail, 30) }}</td>
                            <td class="text-center align-middle text-wrap">{{ registration.pill_status }}</td>
                            <td class="text-center align-middle text-wrap">{{ registration.message_status }}</td>
                            <td class="text-center align-middle text-wrap">{{ formatDate(registration.created) }}</td>
                        </tr>
                    </tbody>
                </table>   
              </div>
            </div>
          </div>
        </div>

        <LastUpdateInfo />
      </div>
    </div>
  </div>  
</template>

<script>
  import {http, http_file, url_from_app_user} from "../http/index";
  import lysLogo from "@/assets/img/icon-lys.png";
  import Loading from "@/views/components/Loading.vue";
  import MessageCard from "@/views/components/MessageCard.vue";
import LastUpdateInfo from "./components/LastUpdateInfo.vue";

  export default {
    name: "PillRegistrations",

    components : {
      Loading,
      LastUpdateInfo,
      "message-card": MessageCard,
    },

    mounted() {
      this.getListRegistrations();
    },

    data() {
      return {
        loading: true,
        lysLogo,
        countRegistrations:{
          clicked: 0,
          sent_pill: 0,
          waiting: 0,
          error: 0,
          sent_mensage: 0,
          status_answered: 0,
          status_initial: 0,
        },
        received_id: this.$route.params.id,     
      };
    },

    methods: {
      formatDate(date) {
        const dateObj = new Date(date);
        const day = dateObj.getDate().toString().padStart(2, "0");
        const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
        const year = dateObj.getFullYear();
        const hours = dateObj.getHours().toString().padStart(2, "0");
        const minutes = dateObj.getMinutes().toString().padStart(2, "0");
        const seconds = dateObj.getSeconds().toString().padStart(2, "0");

        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;      
      },

      getListRegistrations() {
        let payload = {
          token_user: localStorage.getItem("token"),
          current_page: 1,
          pill_id: this.received_id,      
        };
      
        http.post('api/v1/dashboard/pills-registrations/', payload)
        .then((response) => {
          this.PillsRegistrations = response.data.data;
          this.countRegistrations.clicked = response.data.pill_status_clicked ?? 0;
          this.countRegistrations.sent_pill = response.data.pill_status_sent ?? 0;
          this.countRegistrations.waiting = response.data.pill_status_waiting ?? 0;
          this.countRegistrations.status_answered = response.data.pill_status_answered ?? 0;
          this.countRegistrations.error = response.data.status_error ?? 0;
          this.countRegistrations.sent = response.data.status_sent ?? 0;
          this.countRegistrations.status_initial = response.data.status_initial ?? 0;      
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
      },

      // Verifica se o texto é uma string, caso contrário, define como uma string vazia
      truncateText(text, length) {
        text = text || '';
        return text.length > length ? text.substring(0, length) + '...' : text;
      },
    },
  }
</script>

<style scoped>
</style>