<template>
    <div class="container-fluid py-4">
        <div class="row">
            <div class="col-12">
                <div class="card my-4">
                    <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                        <div class="row bg-gradient-info shadow-black border-radius-lg pt-4 pb-3">
                            <h6 class="col text-white text-capitalize ps-3">User Control</h6>
                            <div class="col" v-if="loading == false">
                                <a v-if="this.data_users.users.length < this.data_users.total_users" class="btn-outline-dark btn-inline btn-sm m-1" data-bs-toggle="modal" data-bs-target="#createnewuser" id="btn">
                                    <i class="bi bi-person-add"></i>
                                </a>
                                <div class="btn-outline-dark btn-inline btn-sm m-1" id="btn">
                                    <i class="bi bi-clipboard-plus"></i> {{this.data_users.users.length}} de {{ this.data_users.total_users }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <Loading message="Loading your users" :loading = "loading"/>

                    <UserTable :data_users ="data_users" v-if="loading == false"></UserTable>

                    <LastUpdateInfo />

                    <!-- Modal  new user -->
                    <div class="modal fade" id="createnewuser" tabindex="-1" aria-labelledby="createnewuserLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h1 class="modal-title fs-5" id="createnewuserlLabel">Create New User</h1>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>

                                <div class="modal-body">
                                    <div class="card bg-light border" id="card-newuser">
                                        <div class="card-body py-2 px-2">                        
                                            <div class="d-flex flex-column gap-2">
                                                <label for="floatingName" class="form-label py-0 my-0 text-dark font-weight-bold">Name</label>
                                                <div class="input-group input-group-outline">                                
                                                    <input type="text" class="form-control form-control-default" v-model="first_name" id="floatingName" placeholder="First name">
                                                </div>
                                            </div>

                                            <div class="mt-3 d-flex flex-column gap-2">
                                                <label for="floatingSurname" class="form-label py-0 my-0 text-dark font-weight-bold">Surname</label>
                                                <div class="input-group input-group-outline">                                
                                                    <input type="text" class="form-control form-control-default" v-model="last_name" id="floatingSurname" placeholder="Surname">
                                                </div>
                                            </div>

                                            <div class="mt-3 d-flex flex-column gap-2">
                                                <label for="floatingEmail" class="form-label py-0 my-0 text-dark font-weight-bold">E-mail</label>
                                                <div class="input-group input-group-outline">                                
                                                    <input type="email" class="form-control form-control-default" v-model="email" id="floatingEmail" placeholder="name@example.com">
                                                </div>
                                            </div>

                                            <div class="mt-3 d-flex flex-column gap-2">
                                                <label for="floatingPassword" class="form-label py-0 my-0 text-dark font-weight-bold">Password</label>
                                                <div class="input-group input-group-outline">
                                                    <input type="password" class="form-control form-control-default" v-model="password" id="floatingPassword" placeholder="Password">
                                                </div>
                                            </div>
                                           
                                            <div class="form-check form-switch d-flex align-items-center gap-3 mt-2">
                                                <input class="form-check-input mb-1" type="checkbox" role="switch" id="flexSwitchCheckChecked" value="true" v-model="is_report" checked>
                                                <label class="form-check-label mt-2 mb-0" for="flexSwitchCheckChecked">Receive reports</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                    <button type="button" class="btn btn-success" data-bs-dismiss="modal" @click="Create_user">Create</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {http} from "../http/index";
    import Loading from "@/views/components/Loading.vue";
    import UserTable from "@/views/components/UserTable.vue";
    import LastUpdateInfo from "@/views/components/LastUpdateInfo.vue";

    export default {
        name: "ControUsers",
    
        components : {
            Loading,
            UserTable,
            LastUpdateInfo,
        },

        mounted() {
            setTimeout(() => {
                this.get_users_from_API()
            }, 3000);
        },

        data() {
            return {
                loading: true,
                actualy_user_token: localStorage.getItem("token"),
                is_report: false,
            };
        },

        methods: {
            get_users_from_API: function() {
                var payload = {
                    token: localStorage.getItem("token")
                }

                http.post("/api/v1/dashboard/list-users/", payload)
                .then((response) => {
                    this.data_users = response.data
                })
                .catch(error => {
                    console.log(error.message)
                }).finally(() => {
                    this.loading = false;
                })
            },

            Create_user: async function() {
                this.loading = true;

                var payload={
                    token: localStorage.getItem("token"),
                    first_name: this.first_name,
                    last_name: this.last_name,
                    email: this.email,
                    password: this.password,
                    is_report: this.is_report
                }
                
                await http.post('/api/v1/dashboard/new-user/', payload)
                .then(response => {
                    window.location.reload();
                })
                .catch(error => {
                })
                .finally(() => {                    
                    this.limpaCampos();
                    this.loading = false;
                })
            },

            limpaCampos: function() {
                this.first_name = "";
                this.last_name = "";
                this.email = "";
                this.password = "";
                this.is_report = false;
            }
        }
    };
</script>

<style scoped>
    #td-list-users{
        color: white;
        text-align: center;
        box-shadow: 0px 0px 5px 0px #000000;
        border-radius: 3rem;
        padding: 0.3rem 0.1rem 0.3rem 0.0rem;
        width: 1px;
        font-size: 0.7rem;
        font-weight: 500;
        background-color: #1e1e2f;
        transition: all 0.2s ease-in-out;
        width: 10px;
        height: 30px;  
    }

    #btn {
        color: white;
        text-align: right;
        box-shadow: 0px 0px 5px 0px #000000;
        border-radius: 3rem;
        padding: 0.5rem 1rem;
        font-size: 1rem;
        font-weight: 100;
        background-color: #1e1e2f;
        transition: all 0.2s ease-in-out;
        float: right;    
    }

    .label{
        font-size: 1rem;
        font-weight: 500;
        color: white;
        background-color: #1e1e2f;
        border-radius: 3rem;
        padding: 0.5rem 1rem;
        box-shadow: 0px 0px 5px 0px #000000;
        transition: all 0.2s ease-in-out;
    }

    #card-newuser{
        padding: 1rem;
        border-radius: 1rem;
        font-size: 5rem;
    }

    #flexSwitchCheckChecked {
        background-color: #bdc3c7;
        transition: all 0.3s;
    }

    #flexSwitchCheckChecked:checked {
        background-color: #4caf50;
        transition: all 0.3s;
    }
</style>
