<template>

    <div class="table-responsive align-items-center mb-0" style = "margin: 0px 20px;">

    <table class="table-responsive table table-striped table-hover">
        <thead>
          <tr>

              <th class="text-left px-0">File</th>
              <th class="text-center">Batch</th>
              <th class="text-center">Project</th>
              <th class="text-center">Date</th>
              <th class="text-center">Download</th>

          </tr>
        </thead>

        <tbody>        
            <tr v-for="rep in returnAPI.reports" :key="rep.id">
                <td class="text-left align-middle" wrap="wrap" style="white-space: normal; overflow-wrap: break-word; word-wrap: break-word;"><span class="file">{{rep.report_name}}</span></td>
                <td class="text-center align-middle">{{rep.batch}}</td>
                <td class="text-center align-middle">{{rep.project_name}}</td>
                <td class="text-center align-middle">{{formateDate(rep.modified)}}</td>
                <td class="text-center align-middle">
                    <button v-if="outOfDate(rep.modified) === true" class="btn bg-gradient-info mb-0" @click="download(rep.url_file)">Download</button>
                    <button v-else class="btn bg-gradient-secondary mb-0" disabled>Expired</button>
                </td>

            </tr>

        </tbody>
      </table>
    
    </div>

</template>

<script>

import MaterialButton from "@/components/MaterialButton.vue";

    export default {
    name: "ReportTable",

    components : {
        MaterialButton 
    },

    methods : {        
        download(file){
            window.open(file, '_blank');
        },

        formateDate(date){
            const dataObj = new Date(date);
            const year = dataObj.getUTCFullYear();
            const month = dataObj.getUTCMonth() + 1; 
            const day = dataObj.getUTCDate();
            const hour = dataObj.getUTCHours();
            const minutes = dataObj.getUTCMinutes();

            if (day < 10) {
                if (month < 10) {
                    return `0${day}/0${month}/${year} ${hour}:${minutes}`;
                } else {
                    return `0${day}/${month}/${year} ${hour}:${minutes}`;
                }
            } else if (month < 10) {
                return `${day}/0${month}/${year} ${hour}:${minutes}`;
            }

            return `${day}/${month}/${year} ${hour}:${minutes}`;
        },

        outOfDate(date){
            const currentDate = new Date();
            const dataObj = new Date(date);

            // Calcula a diferença em milissegundos
            const differenceInMillis = currentDate - dataObj;

            // Converte a diferença de milissegundos para dias
            const differenceInDays = differenceInMillis / (1000 * 60 * 60 * 24);

            // Retorna false se a diferença for maior que 16 dias, caso contrário true
            return differenceInDays < 8;
        }
    },

    props: {

        returnAPI: {
            type: Array,
            required: true
        }
  }
}
</script>

<style>
.file{
    font-weight: 800;
    color: #616161
}
</style>