<template>
  <div class="container-fluid p-1 position-relative ">
    <div class="row">
      <div class="d-flex justify-content-between w-100">
        <div class="px-1">
          <router-link
            :to="{ name: 'BatchParticipantsID', params: { id: project } }"
            class="btn bg-gradient-info top-button"
          >
            <i class="bi bi-arrow-left-circle"></i> &nbsp;&nbsp;Back
          </router-link>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card my-4">
        <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2 mb-4">
          <div class="row bg-gradient-info shadow-black border-radius-lg pt-4 pb-3 mb-3">
            <h6 class="col text-white text-capitalize ps-3">Participants</h6>
          </div>
          
          <Loading message="Loading datas" :loading = "loading"/>

          <div v-if="loading == false" class="col-lg-20 mb-md-0 mb-4">
            <div class="card">
              <div class="row mx-3 my-3 d-flex gap-4 justify-content-center">
                <div class="card border border-info col" style="margin-bottom: 1%;">
                  <h6>Course name</h6>
                  <b style="text-transform: capitalize;">{{ this.data_enrollment_info.course_info }}</b>
                </div>

                <div class="card border border-info col" style="margin-bottom: 1%;">
                  <h6>Course code</h6>
                  <b>{{ this.data_enrollment_info.project_info }}</b>
                </div>

                <div class="card border border-info col" style="margin-bottom: 1%;">
                  <h6>Sender</h6>
                  <b>{{ this.data_enrollment_info.sender_enroll }}</b>
                </div>
              </div>

              <div class="row mx-3 my-3 d-flex gap-4 justify-content-center">
                <div class="card border border-info col" style="margin-bottom: 1%;">
                  <h6>Status enrollment</h6>
                  <b style="text-transform: capitalize;">{{ this.data_enrollment_info.status_enroll }}</b>
                </div>
                
                <div class="card border border-info col" style="margin-bottom: 1%;">
                  <h6>Enrollment created</h6>
                  <b>{{ formatDate(this.data_enrollment_info.inscription_data) }}</b>
                </div>
                
                <div class="card col" style="margin-bottom: 1%; opacity: 0;"></div>                  
              </div>
            </div>

            <nav>               
              <div class="nav nav-tabs" id="nav-tab" role="tablist" style="margin-top: 2.5%; margin-bottom: 1%;">
                <button class="nav-link text-dark font-weight-bold" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Inscriptions Info LP</button>

                <button class="nav-link text-dark font-weight-bold" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Inscriptions Info Bot</button>

                <button class="nav-link text-dark font-weight-bold" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Depositions</button>                  
              </div>
            </nav>

            <div class="tab-content" id="nav-tabContent">                
              <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab" tabindex="0">
                <div v-if="data_enrollment_info.info_LP.length > 0" class="card d-flex flex-row flex-wrap justify-content-start gap-3 my-3 p-3 border border-secondary">                    
                  <div v-for="data_lp in data_enrollment_info.info_LP" :key="data_lp.field_name" class="w-30 m-0 p-0">
                    <div class="form-group">
                      <label class="form-label fs-6">{{ data_lp.field_name }}</label>

                      <input type="text" :value="data_lp.response_data" class="card px-3 form-control border text-secondary fs-6 bg-white" readonly>
                    </div>
                  </div>
                </div>
              </div>

              <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab" tabindex="0">
                <div v-if="data_enrollment_info.info_bot.length > 0" class="card d-flex flex-row flex-wrap justify-content-start gap-3 my-3 p-3 border border-secondary">                    
                  <div v-for="data_bot in data_enrollment_info.info_bot" :key="data_bot.field_name" class="w-30 m-0 p-0">
                    <div class="form-group">
                      <label class="form-label fs-6">{{data_bot.fild_name}}</label>

                      <input type="text" :value="data_bot.response_data" class="col card px-3 form-control border text-secondary fs-6 bg-white" readonly>
                    </div>
                  </div>
                </div>
              </div>

              <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab" tabindex="0">
                <div class="accordion-1">
                  <div class="container">                      
                    <div class="row">
                      <div class="col-md-10 mx-auto">
                        <div v-for="data_depositions in data_enrollment_info.depositions" :key="data_depositions.id" class="accordion" :id="'accordionDep'+data_depositions.id">
                          <div class="accordion-item mb-3">
                            <h5 class="accordion-header" id="headingOne">
                              <button class="accordion-button border-bottom font-weight-bold collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapseOne'+data_depositions.id" aria-expanded="false" :aria-controls="'collapseOne'+data_depositions.id">
                                {{data_depositions.lesson_reference}}
                                <i class="collapse-close fa fa-plus text-xs pt-1 position-absolute end-0 me-3" aria-hidden="true"></i>
                                <i class="collapse-open fa fa-minus text-xs pt-1 position-absolute end-0 me-3" aria-hidden="true"></i>
                              </button>
                            </h5>

                            <div :id="'collapseOne'+data_depositions.id" class="accordion-collapse collapse" aria-labelledby="headingOne" :data-bs-parent="'#accordionDep'+data_depositions.id" style="">
                              <div class="accordion-body text-sm opacity-8">
                                <div class="d-flex justify-content-center align-items-center gap-3">
                                  <div class="d-flex justify-content-center card border border-secondary p-3">
                                    <div class="card-header text-center">
                                      <h6>Score</h6>
                                    </div>

                                    <div class="card-body text-center fs-6">
                                      <div v-if="data_depositions.score == 1">
                                        <i class="bi bi-star-fill" style="color: maroon;"></i>
                                      </div>

                                      <div v-if="data_depositions.score == 2">
                                        <i class="bi bi-star-fill" style="color: maroon;"></i>
                                        <i class="bi bi-star-fill" style="color: darkred;"></i>        
                                      </div>

                                      <div v-if="data_depositions.score == 3">
                                        <i class="bi bi-star-fill" style="color: maroon;"></i>
                                        <i class="bi bi-star-fill" style="color: darkred;"></i>
                                        <i class="bi bi-star-fill" style="color: sienna;"></i>
                                      </div>

                                      <div v-if="data_depositions.score == 4">
                                        <i class="bi bi-star-fill" style="color: maroon;"></i>
                                        <i class="bi bi-star-fill" style="color: darkred;"></i>
                                        <i class="bi bi-star-fill" style="color: sienna;"></i>
                                        <i class="bi bi-star-fill" style="color: orange"></i>
                                      </div>

                                      <div v-if="data_depositions.score == 5">
                                        <i class="bi bi-star-fill" style="color: maroon;"></i>
                                        <i class="bi bi-star-fill" style="color: darkred;"></i>
                                        <i class="bi bi-star-fill" style="color: sienna;"></i>
                                        <i class="bi bi-star-fill" style="color: orange"></i>
                                        <b><i class="bi bi-star-fill" style="color: gold;"></i></b>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="d-flex justify-content-center card border border-secondary p-3">
                                    <div class="card-header text-center">
                                      <h6>Deposition</h6>
                                    </div>
                                    <div class="card-body text-center">                                        
                                      <b class="fs-6 text-center">{{data_depositions.text_deposition}}</b>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <LastUpdateInfo />
      </div>
    </div>
  </div>
</template>

<script>
  import { http } from "../http/index";
  import lysLogo from "@/assets/img/icon-lys.png";
  import MaterialButton from "@/components/MaterialButton.vue";
  import Loading from "@/views/components/Loading.vue";
  import MiniStatisticsCard from "./components/MiniStatisticsCard.vue";
  import LastUpdateInfo from "./components/LastUpdateInfo.vue";

  export default {
    name: "ControUsers",

    components : {
      Loading,
      MaterialButton,
      LastUpdateInfo,
      MiniStatisticsCard,
    },

    data() {
      return {
        loading: true,
        lysLogo,
        project: this.$route.params.course_id,
        token_user: localStorage.getItem("token"),
        enroll_selected: this.$route.params.id,
        data_enrollment_info: {
          course_info: null,
          project_info: null,
          status_enroll: null,
          sender_enroll: null, 
          inscription_data: null, 
          info_LP: null,
          info_bot: null,
          depositions: null
        },
      };
    },

    mounted() {
      this.enrollmentFormClient();
    },

    methods: {
      formatDate(date, time = true) {
        const dateObj = new Date(date);
        const day = dateObj.getDate().toString().padStart(2, "0");
        const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
        const year = dateObj.getFullYear();
        const hours = dateObj.getHours().toString().padStart(2, "0");
        const minutes = dateObj.getMinutes().toString().padStart(2, "0");
        const seconds = dateObj.getSeconds().toString().padStart(2, "0");
        
        if (this.allEnrollments) {
          this.number_enroll = this.allEnrollments.length;
        } else {
          this.number_enroll = 0;
        }

        if (!time) {
          return `${year}\-${month}\-${day}`;
        }else{
          return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
        }        
      },

      enrollmentFormClient() {
        let payload = {
          token_user: this.token_user,
          course_id: this.project,
          enrollment_id: this.enroll_selected,
        }
        
        http.post('/api/v1/dashboard/select-enrollment-from-client/', payload)
        .then((response) => (this.data_enrollment_info = response.data.infor_enrollments))
        .finally(() => {          
          this.data_enrollment_info.course_info = this.data_enrollment_info.course_info;
          this.data_enrollment_info.project_info = this.data_enrollment_info.project_info;

          this.data_enrollment_info.status_enroll = this.data_enrollment_info.status;
          this.data_enrollment_info.sender_enroll = this.data_enrollment_info.sender;
          this.data_enrollment_info.inscription_data = this.data_enrollment_info.created;
        
          this.data_enrollment_info.info_LP = this.data_enrollment_info.info_lp;
          this.data_enrollment_info.info_bot = this.data_enrollment_info.info_both;
          this.data_enrollment_info.depositions = this.data_enrollment_info.depositions;

          this.loading = false;
        });
      },
    }
  }
</script>

<style scoped>
</style>