<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="d-flex justify-content-between w-100">
        <div class="px-1">
          <router-link
            :to="{ name: 'PushBatchesInfo' }"
            class="btn bg-gradient-info top-button"
          >
            <i class="bi bi-arrow-left-circle"></i> &nbsp;&nbsp;Back
          </router-link>          
        </div>
      </div>
    </div>
    
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2 mb-4">
            <div class="row bg-gradient-info shadow-black border-radius-lg pt-3 pb-3 mb-3 w-100 d-flex justify-content-between align-items-center">
              <h6 class="col text-white text-capitalize ps-3 m-0">Invitations Batch Details</h6>
              <button v-if="loading == false" class="btn bg-gradient-dark w-15 m-0 mx-2" style="color:azure"  @click="printCurrentPage">Report Generate</button>
            </div>            

            <Loading message="Loading push batch infos" :loading = "loading"/>

            <div v-if="loading == false" class="col-lg-20 mb-md-0 mb-4">
              <!-- Cards dos Status das Messages e Students -->
              <div class="row d-flex justify-content-center">
                <message-card
                  :nameCard="'Total Base'"
                  :amount="number_messages"
                  :color="'info'"
                  :size="2"
                ></message-card>
                
                <message-card
                  :nameCard="'Conversion'"
                  :amount="parcial + '% de ' + number_sent"
                  :color="'warning'"
                  :size="2"
                ></message-card>

                <message-card
                  :nameCard="'Inscriptions'"
                  :amount="number_inscriptions"
                  :color="'success'"
                  :size="2"
                ></message-card>

                <message-card
                  :nameCard="'Sent Messages'"
                  :amount="number_sent"
                  :color="'success'"
                  :size="2"
                ></message-card>

                <message-card
                  :nameCard="'Failed Messages'"
                  :amount="number_failed"
                  :color="'danger'"
                  :size="2"
                ></message-card>                
              </div>
              
              <div class="card shadow my-4">
                <div class="card-header bg-dark py-1 d-flex align-items-center">
                  <div class="w-100 d-flex justify-content-between align-items-center py-1">
                    <h6 v-if="courseName" class="m-0 font-weight-bold text-light">COURSE: #{{ courseId }} - {{ courseName }}</h6>

                    <button @click="downloadCSV" type="button" class="btn bg-gradient-info rounded-circle px-3 fs-5 m-0"><i class="bi bi-filetype-csv"></i></button>
                  </div>

                  <div v-if="progress_csv < 100 && progress_csv > 0" class="progress" role="progressbar" aria-label="Progress bar" :aria-valuenow="progress_csv" aria-valuemin="0" aria-valuemax="100">
                    <div class="progress-bar bg-info" :style="{ width: progress_csv + '%' }">{{ progress_csv }}%</div>
                  </div>
                </div>

                <div class="card-body">
                  <div class="table-responsive align-items-center mb-0" style = "margin: 0px 20px; border-top-left-radius: 10px !important; border-top-right-radius: 10px !important;">
                    <table class="table table-striped table-hover" id="dataTable">
                      <thead class="text-light bg-info">
                        <tr>
                          <th class="text-left" style="width: 80px;">ID</th>
                          <th class="text-center">Enrollment status</th>
                          <th class="text-center">Student name</th>
                          <th class="text-center">Phone number</th>
                          <th class="text-center">Message status</th>
                          <th class="text-center">Created</th>
                          <th class="text-center">Modified</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="data in allEnrollments" v-bind:key="data.id">
                          <td class="align-middle" style="width: 80px;">#{{ data.id }}</td>
                          <td class="text-center align-middle">{{ data.enrollment_status }}</td>
                          <td class="text-center align-middle" wrap="wrap" style="white-space: normal; overflow-wrap: break-word; word-wrap: break-word;">{{ data.student_name }}</td>
                          <td class="text-center align-middle">{{ data.student_phone_number }}</td>
                          <td class="text-center align-middle">{{ data.status }}</td>
                          <td class="text-center align-middle">{{ formatDate(data.created) }}</td>
                          <td class="text-center align-middle">{{ formatDate(data.modified) }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <LastUpdateInfo />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {http, http_file, url_from_app_user} from "../http/index";
import lysLogo from "@/assets/img/icon-lys.png";
import Loading from "@/views/components/Loading.vue";
import MessageCard from "@/views/components/MessageCard.vue";
import LastUpdateInfo from "./components/LastUpdateInfo.vue";

export default {
  name: "PushBatchView",

  components : {
    Loading,
    LastUpdateInfo,
    "message-card": MessageCard,
  },

  data() {
    return {
      lysLogo,
      loading: true,
      allData: null,
      allEnrollments: null,
      courseId: null,
      courseName: null,
      countEnrollments: {
        initial: 0,
        active: 0,
        completed: 0,
        inactive: 0,
        missingToken: 0,
        noActivity: 0,
        onHold: 0,
      },
      countMessagesStatus: {
        sent: 0,
        resent: 0,
        failed: 0,
        delivered: 0,
        read: 0
      },
      parcial: 0,
      progress_csv: 0,
      number_messages: 0,
      number_sent: 0,
      number_failed: 0,
      number_inscriptions: 0,
      token_user: localStorage.getItem("token"),
    };
  },

  mounted() {
    this.getAllEnrollments();
  },

  methods: {
    formatDate(date) {
      const dateObj = new Date(date);
      const day = dateObj.getDate().toString().padStart(2, "0");
      const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
      const year = dateObj.getFullYear();
      const hours = dateObj.getHours().toString().padStart(2, "0");
      const minutes = dateObj.getMinutes().toString().padStart(2, "0");
      const seconds = dateObj.getSeconds().toString().padStart(2, "0");

      return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;      
    },

    async getAllEnrollments() {
      if (this.$route.params.id != null) {
        var payload = { 
          push_batch_id: this.$route.params.id,
          token_user: this.token_user
        };

        await http.post("/api/v1/client/report_by_push_converting_enrollments_by_user/", payload)
        .then((response) => {
          this.allData = response.data
        })
        .catch((error) => {
          console.log("Error fetching data: ", error.message);
        })
        .finally(() => {
          this.allEnrollments = this.allData.enrollments;
          this.courseId = this.allData.course_id;
          this.courseName = this.allData.course_name;

          // contagem dos status dos messages
          this.countMessagesStatus.sent = this.allData.messages_status.sent;
          this.countMessagesStatus.resent = this.allData.messages_status.resent;
          this.countMessagesStatus.failed = this.allData.messages_status.failed;
          this.countMessagesStatus.delivered = this.allData.messages_status.delivered;
          this.countMessagesStatus.read = this.allData.messages_status.read;
          
          this.number_messages = parseInt(this.countMessagesStatus.sent) + parseInt(this.countMessagesStatus.resent) + parseInt(this.countMessagesStatus.failed) + parseInt(this.countMessagesStatus.delivered) + parseInt(this.countMessagesStatus.read);

          this.number_sent = parseInt(this.countMessagesStatus.sent) + parseInt(this.countMessagesStatus.resent) + parseInt(this.countMessagesStatus.delivered) + parseInt(this.countMessagesStatus.read);

          this.number_failed = parseInt(this.countMessagesStatus.failed);

          // contagem dos status dos enrollments
          this.countEnrollments.initial = this.allData.count_enrollments.initial;
          this.countEnrollments.active = this.allData.count_enrollments.active;
          this.countEnrollments.completed = this.allData.count_enrollments.completed;
          this.countEnrollments.inactive = this.allData.count_enrollments.inactive;
          this.countEnrollments.missingToken = this.allData.count_enrollments.missing_token;
          this.countEnrollments.noActivity = this.allData.count_enrollments.no_activity;
          this.countEnrollments.onHold = this.allData.count_enrollments.on_hold;
          
          this.number_inscriptions = parseInt(this.countEnrollments.active) + parseInt(this.countEnrollments.completed) + parseInt(this.countEnrollments.inactive) + parseInt(this.countEnrollments.noActivity);

          this.parcial = ((this.number_inscriptions / this.number_sent) * 100).toFixed(2);

          if (isNaN(this.parcial)) {
            this.parcial = 0;
          }

          this.loading = false;
        })         
      }        
    },

    printCurrentPage() {
      var elementId = 'printableArea'
      var printContent = document.getElementById(elementId).innerHTML;
      var originalPageStyle = document.getElementsByTagName('style');
      var newWindow = window.open('', '_blank', 'width=800,height=600');
      newWindow.document.open();
      newWindow.document.write('<html><head><title>Print</title>');
      // Copia os estilos para a nova janela
      
      for (var i = 0; i < originalPageStyle.length; i++) {
          newWindow.document.write('<style>' + originalPageStyle[i].innerHTML + '</style>');
      }

      newWindow.document.write('</head><body>');
      newWindow.document.write(printContent);
      newWindow.document.write('</body></html>');
      newWindow.document.close();
      newWindow.print();
    },

    downloadCSV() {
      let csvContent = "ID,Status Enrollment,Nome do Aluno,Status,Número de Telefone,Data de Criação,Data de Modificação\n";
      let processed_enrollments = 0;

      if (this.allEnrollments != null) {
        this.allEnrollments.forEach(item => {
          csvContent +=
            `${item.id},` +
            `${item.enrollment_status},` +
            `${item.student_name},` +
            `${item.status},` +
            `${item.student_phone_number},` +
            `${this.formatDate(item.created)},` +
            `${this.formatDate(item.modified)}\n`;

          processed_enrollments += 1;
          this.progress_csv = (processed_enrollments / this.allEnrollments.length) * 100;
        });

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);

        link.setAttribute('href', url);
        link.setAttribute('download', 'data.csv');
        link.style.visibility = 'hidden';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      setTimeout(() => {
        this.progress_csv = 0;
      }, 2500);        
    },
  }
}
</script>

<style scoped>
  .cursor-click {
    cursor: pointer;
  }
</style>