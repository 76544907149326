<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2 pb-2">
            <div
              class="row bg-gradient-info shadow-black border-radius-lg pt-4 pb-3 mb-3"> 
              <h6 class="col text-white text-capitalize ps-3">Reports</h6>
            </div>       
         
            <Loading message="Loading your reports" :loading = "loading"/>

            <!-- Token not found -->
            <div class="col-12 d-flex justify-content-center" v-if="(Array.isArray(returnAPI.reports) == false && showMessage == true && loading == false)">
              <div id="not_found_token" class="col-4 ps-3 alert alert-danger fw-bold" v-if="error == true">
                Sorry, token not found.
              </div>

              <div id="not_found" class="col-4 ps-3 alert alert-warning fw-bold" v-else>
                Databases files not found.
              </div>
            </div>

            <div id="report" v-else-if="Array.isArray(returnAPI.reports) && loading == false">             
              <div id = "total" role="alert" class= "col ps-3 w-25 alert alert-danger" v-if = "returnAPI.reports.length == 0 && showMessage == true"> There are no reports. </div>
              <div id = "total" role="alert" class= "col ps-3 w-25 alert alert-success" v-if = "returnAPI.reports.length == 1  && showMessage == true"> There's {{ returnAPI.reports.length }} report found. </div>
              <div id = "total" role="alert" class= "col ps-3 w-25 alert alert-success" v-if = "returnAPI.reports.length > 1 && showMessage == true">There're {{ returnAPI.reports.length }} reports found. </div>

              <ReportTable :returnAPI="returnAPI"/>              
            </div>
          </div>

          <LastUpdateInfo />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReportTable from "@/views/components/ReportTable.vue";
import Loading from "@/views/components/Loading.vue";
import {http} from "../http/index";
import LastUpdateInfo from "./components/LastUpdateInfo.vue";

export default {
  name: "Reports",
  
  components : {
    Loading,
    ReportTable,
    LastUpdateInfo,
  },

  data() {        
    return {
      token:  localStorage.getItem("token"),
      returnAPI: [],
      loading: true,
      showMessage : true,
      error: false
    };
  },

  mounted(){
    this.searchReports()
  },

  methods: {
    async searchReports(){
      var payload = {
        token: this.token
      }

      await http.post("/api/v1/dashboard/reports-by-user/", payload)
      .then((response) => {
        this.returnAPI = response.data
        this.showMessage = false        
      })
      .catch(() => {        
        this.returnAPI = false
        this.loading = false
        this.error = true
      })
      .finally(() => {
        if (!Array.isArray(this.returnAPI.reports)) {
          this.showMessage = true
        }
        
        this.loading = false
      })
    }
  }
};
</script>

<style scoped>

#total{
  font-size: 16px;
  color: white;
  text-align: center;
}

#loading{
  padding-bottom: 15px;
  text-align: center;
  vertical-align: middle;
}

#not_found{
  color: white;
  text-align: center;
}

#report{
  padding-top: 5px;
  font-size: 16px;
  color: #424242;
}

</style>
