<template>
    <div class="d-flex justify-content-end mb-2 px-4">
        <div class="row bg-gradient-secondary shadow-black border-radius-lg text-light fw-bold px-2 py-1">
            {{ mensagem }}
        </div>
    </div>
</template>

<script>
    export default {
        name: "LastUpdateInfo",

        data() {
            return {
                mensagem: 'Atualizado agora',
                tempoAtualizacao: new Date()
            };
        },

        mounted() {
            this.atualizarMensagem();
            this.intervalo = setInterval(this.atualizarMensagem, 60000); // Atualiza a cada minuto
        },

        methods: {
            atualizarMensagem() {
                const agora = new Date();
                const diferencaMs = agora - this.tempoAtualizacao;
                const diferencaMinutos = Math.floor(diferencaMs / 60000);

                if (diferencaMinutos === 0) {
                    this.mensagem = 'Atualizado agora';
                } else if (diferencaMinutos < 60) {
                    this.mensagem = `Atualizado há ${diferencaMinutos} minuto(s)`;
                } else {
                    const horas = this.tempoAtualizacao.getHours().toString().padStart(2, '0');
                    const minutos = this.tempoAtualizacao.getMinutes().toString().padStart(2, '0');
                    const dia = agora.getDate().toString().padStart(2, '0');
                    const mes = (agora.getMonth() + 1).toString().padStart(2, '0');
                    const ano = agora.getFullYear();
                    this.mensagem = `Atualizado em ${dia}/${mes}/${ano} às ${horas}:${minutos}`;
                }
            }
        },

        beforeDestroy() {
            clearInterval(this.intervalo);
        }
    }
</script>